<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="layout--main" :class="[{ 'no-scroll': isAppPage }]">
    <div class="content-wrapper">
      <div class="router-view">
        <div class="router-content">
          <div class="content-area__content">
            <!-- <back-to-top
              bottom="5%"
              :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'"
              visibleoffset="500"
              v-if="!hideScrollToTop"
            >
              <vs-button
                icon-pack="feather"
                icon="icon-arrow-up"
                class="shadow-lg btn-back-to-top"
              />
            </back-to-top> -->

            <transition :name="routerTransition" mode="out-in">
              <router-view
                @changeRouteTitle="changeRouteTitle"
                @setAppClasses="
                  (classesStr) => $emit('setAppClasses', classesStr)
                "
              />
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import BackToTop           from 'vue-backtotop'

import navMenuItems        from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import TheNavbarVertical   from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter           from '@/layouts/components/TheFooter.vue'
import themeConfig         from '@/../themeConfig.js'
import VNavMenu            from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import VNavMenuHidden  from '@/layouts/components/vertical-nav-menu/VerticalNavMenuHidden.vue'


// const VxTour = () => import('@/components/VxTour.vue')

export default {
  components: {
    BackToTop,
    TheFooter,
    TheNavbarVertical,
    VNavMenu,
    VNavMenuHidden
    // VxTour
  },
  data () {
    return {
      disableCustomizer : themeConfig.disableCustomizer,
      disableThemeTour  : themeConfig.disableThemeTour,
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      navMenuItems,
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      reduceNotRebound:true

    }

  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(bread_crump => {
        bread_crump.title = this.$t(bread_crump.title)
        return true
      })
    },
    isThemeDark (val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType' (val) {
      this.setNavMenuVisibility(val)
      this.disableThemeTour = true
    },
    hideVerticalMenu () {
      window.location.reload()
    }

  },

  computed: {
    logo () {
      return this.logoImg
    },
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth },
    hideVerticalMenu () { return this.$route.meta.hideVerticalMenu || false }

  },
  methods: {

    changeRouteTitle (title) {
      this.routeTitle = title
    },
    updateNavbarColor (val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    setNavMenuVisibility (layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    }
  },
  created () {

    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)
    if (this.$route.meta.breadcrumb) {
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.filter(bread_crump => {
        bread_crump.title = this.$t(bread_crump.title)
        return true
      })
    }
    // Dynamic Watchers for tour
    // Reason: Once tour is disabled it is not required to enable it.
    // So, watcher is required for just disabling it.
    this.dynamicWatchers.windowWidth = this.$watch('$store.state.windowWidth', (val) => {
      if (val < 1200) {
        this.disableThemeTour = true
        this.dynamicWatchers.windowWidth()
      }
    })

    this.dynamicWatchers.verticalNavMenuWidth = this.$watch('$store.state.verticalNavMenuWidth', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.verticalNavMenuWidth()
    })

    this.dynamicWatchers.rtl = this.$watch('$vs.rtl', () => {
      this.disableThemeTour = true
      this.dynamicWatchers.rtl()
    })
    // If Not AuthUser
    if (!this.is_authUser) {
      this.$store.commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', 'hidden')
      this.navbarType = 'hidden'
    }
  },
  beforeDestroy () {
    Object.keys(this.dynamicWatchers).map(i => {
      this.dynamicWatchers[i]()
      delete this.dynamicWatchers[i]
    })
  }


}

</script>

<style lang="scss" scoped>
.navbar-floating .router-content {
  margin-top: 2.5rem;
}
.router-content.act-as-mode {
  margin-top: 9.5rem;
}
.router-view {
  padding: 1.5rem;
}
</style>
